body {
  background-color: #232323;
}

.App {
  text-align: center;
}

.App-header-image {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  color: #eeeeee;
}

.App-link {
  color: #61dafb;
}

.App-navbar {
  background-color: #282c34;
  min-height: 12vh;
  align-items: center;
}

.App-footer {
  background-color: #232323;
  color: #eeeeee;
  font-size: calc(3px + 1.5vmin);
  display: flex;
  min-height: 3vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  align-items: left;
  justify-content: center;
}
